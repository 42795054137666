import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Business from './components/Business';
import Games from './components/Games';

function App() {

  return (
    <Routes>
      {/* Default route to render the Home component */}
      <Route path="/" element={<Home />} />
      <Route path="/business" element={<Business />} />
      <Route path="/games" element={<Games />} />
    </Routes>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}