import React, { useEffect, useState } from 'react';

const Home = () => {
  const [iframeSrc, setIframeSrc] = useState(`https://playafi.carrd.co${window.location.hash}`);

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window.location.hash;
      console.log('Hash changed:', currentHash); // Log the current hash
      setIframeSrc(`https://playafi.carrd.co${currentHash}`);
    };

    console.log('Initial load hash:', window.location.hash); // Log the initial hash

    // Add a listener for the hashchange event
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div>
      <iframe
        src={iframeSrc}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="PlayaFi"
      ></iframe>
    </div>
  );
};

export default Home;